import { collection, onSnapshot, doc, setDoc } from 'firebase/firestore'
import { db } from 'controllers/db'
import { DBT } from 'types/internal'

export const dbSubscribeToLocalization = (
  callback: (conf: Record<string, Record<string, string>>) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'twoFactorLocalization')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as
          | Record<string, Record<string, string>>
          | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToLocalization error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToLocalization error', e)
    return null
  }
}

export const dbUpdateLocalization = async (
  conf: Partial<DBT.AIPromptConfT>
) => {
  const ref = doc(collection(db, 'settings'), 'twoFactorLocalization')
  await setDoc(ref, conf)
}
