import { HStack, Image, Text, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { DBT } from 'types/internal'

type Props = {
  user: DBT.VegasUserT
}

const UserProfile: FC<Props> = ({ user }) => {
  return (
    <HStack w='full' spacing={6} align={'start'}>
      {user.photoUrl && (
        <Image
          boxSize='100px'
          objectFit='cover'
          src={user.photoUrl}
          alt={user.name}
        />
      )}
      <VStack align={'start'} spacing={1}>
        <Text color={'zinc.800'} fontSize={'md'} fontWeight={'semibold'}>
          {user.name}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.id}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.jobTitle} {user.company}
        </Text>
        <Text color={'zinc.600'} fontSize={'xs'} fontWeight={'normal'}>
          {user.city} {user.country}
        </Text>
      </VStack>
    </HStack>
  )
}

export default UserProfile
