import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import { dbCreateClient } from 'controllers/clients'
import { Client } from 'types/internal'
import { useNavigate } from 'react-router'

const Clients = () => {
  const clients = useSelector(state => state.clients)
  const navigate = useNavigate()

  // const sortedClients = useMemo = (())

  const renderClient = (cl: Client) => {
    return (
      <HStack
        w='full'
        key={cl.id}
        _hover={{ bg: 'gray.50', cursor: 'pointer' }}
        px={4}
        py={2}
        justify={'space-between'}
        onClick={() => navigate(`/clients/${cl.id}`)}
      >
        <Text>{cl.title}</Text>
        <Text>{'>'}</Text>
      </HStack>
    )
  }

  const content = (
    <VStack>
      <Card w='lg'>
        <CardBody px={0}>
          <VStack divider={<Divider />} w='full' spacing={0}>
            {_.map(clients, renderClient)}
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  )

  const noClients = (
    <VStack w='full' h='full'>
      <Text color='gray.600' pt={12}>
        No clients
      </Text>
    </VStack>
  )

  const actions = (
    <Button variant={'solid'} colorScheme='teal' onClick={dbCreateClient}>
      Add Client
    </Button>
  )

  return (
    <Box w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Clients'} actions={actions} />
      <Box w='full' h='full' overflow={'hidden'} bg='gray.50' p={4}>
        {clients !== null && _.isEmpty(clients) ? noClients : content}
      </Box>
    </Box>
  )
}

export default Clients
