import {
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  Text,
  VStack
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import UsersSearch from 'components/UsersSearch'
import { dbSaveVegasUser, dbSubscribeToUser } from 'controllers/vegas'
import { Unsubscribe } from 'firebase/firestore'
import { useState, useRef, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { DeleteIcon } from '@chakra-ui/icons'

import AddVegasUserModal, { IAddVegasUserModal } from 'modals/AddVegasUserModal'
import SearchByPhoto from 'pages/vegasUsers/SearchByPhoto'
import { isDev } from 'src/config'
import UserPhotos from 'pages/vegasUsers/UserPhotos'
import { DBT } from 'types/internal'
import JsonInput from 'pages/vegasUsers/JsonInput'
import UserProfile from 'pages/vegasUsers/UserProfile'
import UserParamsList from 'pages/vegasUsers/UserParamsList'

const VegasUsers = () => {
  const [userId, setUserId] = useState<string>()
  const [user, setUser] = useState<DBT.VegasUserT>()
  const [savedUser, setSavedUser] = useState<object>()
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')
  const addVegasUserModalRef = useRef<IAddVegasUserModal>(null)

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    if (userId) {
      unsubscribeRef.current = dbSubscribeToUser(userId, u => {
        setUser(u as DBT.VegasUserT)
        setSavedUser(u)
      })
    }
  }, [userId])

  console.log('user', user)

  const clearUser = useMemo(() => {
    return _.omit(user, ['photoUrl', 'photos', 'faces', 'id'])
  }, [user])

  const onSelectUser = (userId: string) => {
    console.log('onSelectUser', userId)
    setUserId(userId)
  }

  const renderSearch = () => {
    return (
      <VStack p={4} w='full' align={'start'} spacing={6}>
        <UsersSearch onSelect={onSelectUser} />
        <SearchByPhoto onUserFound={onSelectUser} />
      </VStack>
    )
  }

  const handleEdit = (key: string, newValue: string) => {
    setUser({ ...user, [key]: newValue } as DBT.VegasUserT)
  }

  const handleDelete = (key: string) => {
    const newValue = { ...user }
    delete newValue[key]
    setUser(newValue as DBT.VegasUserT)
  }

  const handleAdd = (newKey: string, newValue: string) => {
    if (
      newKey &&
      newValue &&
      newKey !== 'id' &&
      newKey !== 'photoUrl' &&
      newKey !== 'photos' &&
      newKey !== 'faces'
    ) {
      setUser({ ...user, [_.camelCase(newKey)]: newValue } as DBT.VegasUserT)
    }
  }

  const renderUserParams = () => {
    if (user) {
      return (
        <UserParamsList
          user={clearUser}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleAdd={handleAdd}
        />
      )
    }
  }

  const onSave = () => {
    if (user) {
      dbSaveVegasUser(user)
    }
  }

  const onUserCreated = (userId: string) => {
    setUserId(userId)
  }

  const renderPhotos = () => {
    if (user) {
      return <UserPhotos user={user} />
    }
  }

  const actions = (
    <HStack>
      <Button
        variant={'primary'}
        size='sm'
        onClick={() => addVegasUserModalRef.current?.open()}
      >
        Add user
      </Button>
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(user, savedUser)}
        size='sm'
      >
        Save
      </Button>
    </HStack>
  )

  const onUpdateUserByJson = (upd: Record<string, any>) => {
    const newUser = {
      ...user,
      ...upd
    }
    setUser(newUser as DBT.VegasUserT)
  }

  const renderJsonInput = () => {
    if (user) {
      return <JsonInput data={clearUser} onChange={onUpdateUserByJson} />
    }
  }

  if (isDev) {
    return (
      <VStack
        w='full'
        h='full'
        bg='white'
        overflow={'hidden'}
        pt={16}
        spacing={6}
      >
        <Text>Managing users is available in production environment only</Text>
        <Button
          variant='primary'
          onClick={() =>
            window.open('https://admin.facesign.ai/vegas_users', '_blank')
          }
        >
          Open prod admin panel
        </Button>
      </VStack>
    )
  } else {
    return (
      <VStack w='full' h='full' bg='white' overflow={'hidden'}>
        <NavBar title={'Vegas users'} actions={actions} />
        {renderSearch()}
        <VStack
          w='full'
          overflow={'auto'}
          p={{ base: 2, lg: 6 }}
          h='full'
          flex={1}
          spacing={6}
        >
          {/* <VStack w='full' spacing={10} flex={1}> */}
          {user && <UserProfile user={user} />}
          {renderUserParams()}
          {renderJsonInput()}
          {renderPhotos()}
          {/* </VStack> */}
        </VStack>
        <AddVegasUserModal
          ref={addVegasUserModalRef}
          onUserCreated={onUserCreated}
        />
      </VStack>
    )
  }
}

export default VegasUsers
