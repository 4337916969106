import { onAuthStateChanged, User } from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import _ from 'lodash'

import store from 'model/store'
import { auth } from 'controllers/db'
import { receiveAuthData } from 'model/actions'
import { clearListeners } from 'controllers/listeners'
import { AuthUser } from 'types/internal'
import { fetchClients } from 'controllers/clients'
import { fetchLangs } from 'controllers/langs'

export const initData = async (): Promise<void> => {
  try {
    await Promise.all([fetchClients(), fetchLangs()])
  } catch (e) {
    console.error('er', e)
  }
}

const init = async (): Promise<void> => {
  await initData()
}

let initUrl = '/'

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  if (!_.isNil(authData)) {
    store.dispatch(receiveAuthData(authData.toJSON() as AuthUser))
    await init()
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/signin')) {
      navigate(initUrl)
    }
  } else {
    store.dispatch(receiveAuthData(null))
    // setExistingUser(null)
    clearListeners()
    let needToRedirect = true
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/signin')) {
      needToRedirect = false
    }
    if (needToRedirect) {
      navigate('/signin')
    }
    // store.dispatch(logoutUser())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    initUrl = window.location.pathname
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    console.log('app initialization error', e)
  }
}
