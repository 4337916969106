import { configureStore } from '@reduxjs/toolkit'
import authData from 'model/reducers/authData'
import clients from 'model/reducers/clients'
import langs from 'model/reducers/langs'

const store = configureStore({
  reducer: {
    authData,
    clients,
    langs
  }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
