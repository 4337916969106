import { collection, onSnapshot, doc, setDoc } from 'firebase/firestore'
import { db } from 'controllers/db'
import { DBT } from 'types/internal'

export const dbSubscribeToAnalysis = (
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'twoFactorDemoAnalysis')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToAnalysis error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToAnalysis error', e)
    return null
  }
}

export const dbUpdateAnalysis = async (conf: Partial<DBT.AIPromptConfT>) => {
  const ref = doc(collection(db, 'settings'), 'twoFactorDemoAnalysis')
  await setDoc(ref, conf)
}
