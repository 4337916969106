import { createReducer } from '@reduxjs/toolkit'
import { receiveClients, logout } from 'model/actions'
import { Client } from 'types/internal'

const initialState: Record<string, Client> = {}

const authDataReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveClients, (state, action) => action.payload)
    .addCase(logout, () => initialState)
})

export default authDataReducer
