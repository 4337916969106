import { VStack, Button } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { dbUpdateLangs } from 'controllers/langs'
import AddLangRow from 'pages/langs/AddLangRow'
import { useEffect, useMemo, useState } from 'react'
import { DBT } from 'types/internal'
import _ from 'lodash'
import LangRow from 'pages/langs/LangRow'
import { useSelector } from 'model/hooks'

const Langs = () => {
  const savedLangs = useSelector(state => state.langs)
  const [langs, setLangs] = useState<Record<string, DBT.LangT>>({})

  const sortedLangs = useMemo(() => {
    return _.sortBy(langs, 'createdAt')
  }, [langs])

  useEffect(() => {
    setLangs(savedLangs)
  }, [savedLangs])

  const renderLangs = () => {
    return (
      <VStack spacing={0} align='stretch' w='full'>
        {_.map(sortedLangs, lang => (
          <LangRow
            key={lang.id}
            lang={lang}
            onUpdate={updatedLang => {
              const updatedLangs = { ...langs, [lang.id]: updatedLang }
              setLangs(updatedLangs)
            }}
          />
        ))}
      </VStack>
    )
  }

  const onAddLang = (newLang: DBT.LangT) => {
    const updatedLangs = { ...langs, [newLang.id]: newLang }
    setLangs(updatedLangs)
  }

  const actions = (
    <Button
      variant={'outline'}
      colorScheme='white'
      onClick={() => dbUpdateLangs(langs)}
      isDisabled={_.isEqual(langs, savedLangs)}
      size='sm'
    >
      Save
    </Button>
  )

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Languages'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6}>
        {/* Add content for the Languages page here */}
        {renderLangs()}
        <AddLangRow onAdd={onAddLang} />
      </VStack>
    </VStack>
  )
}

export default Langs
