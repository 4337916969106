import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  VStack,
  ModalCloseButton,
  Input
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import Label from 'components/Label'
import { dbCreateVegasUser } from 'controllers/vegas'

export interface IAddVegasUserModal {
  open: () => void
}

type Props = {
  onUserCreated: (userId: string) => void
}

const AddVegasUserModal: ForwardRefRenderFunction<IAddVegasUserModal, Props> = (
  { onUserCreated },
  ref: Ref<unknown>
) => {
  const [name, setName] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: () => {
      setName('')
      onOpen()
    }
  }))

  const onCreate = () => {
    if (!_.isEmpty(_.trim(name))) {
      const userId = 'u_' + generateId()
      const user: Record<string, any> = {
        id: userId,
        name
      }
      dbCreateVegasUser(user)
      onUserCreated(userId)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add user</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4}>
          <VStack spacing={8}>
            <FormControl isRequired w='full'>
              <Label>User name</Label>
              <Input
                id='name'
                type='text'
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={async e => {
                  if (e.key === 'Enter') {
                    onCreate()
                  }
                }}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'blue'}
            isDisabled={name.length === 0}
            onClick={onCreate}
          >
            {'Add'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddVegasUserModal)
