import { initializeApp } from 'firebase/app'
import {
  doc,
  collection,
  initializeFirestore,
  deleteField
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { mapObject } from 'shared/utils/object'
import { getFunctions } from 'firebase/functions'
import config from 'src/config'
import { getStorage } from 'firebase/storage'

// console.log('config', config)

const firebaseApp = initializeApp(config)

export const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
})
export const auth = getAuth()

export const storage = getStorage(firebaseApp, config.vegasUsersBucket)

export const generateId = (): string => {
  return doc(collection(db, 'tmp')).id
}

export const dbOmit = <T>(data: Record<string, T>) => {
  return mapObject(data, value => (value === undefined ? deleteField() : value))
}

export const functions = getFunctions()
