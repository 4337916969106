import React from 'react'
import {
  Box,
  Flex,
  Text,
  Switch,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Tag
} from '@chakra-ui/react'
import { DBT } from 'types/internal'

interface LangRowProps {
  lang: DBT.LangT
  onUpdate: (updatedLang: DBT.LangT) => void
}

const LangRow: React.FC<LangRowProps> = ({ lang, onUpdate }) => {
  const handleTitleChange = (newTitle: string) => {
    onUpdate({ ...lang, title: newTitle })
  }

  const handleSwitchChange = () => {
    onUpdate({ ...lang, isDisabled: !lang.isDisabled })
  }

  return (
    <Box p={4} py={1} w='full'>
      <Flex justifyContent='space-between' alignItems='center'>
        <HStack alignItems='center' spacing={6}>
          <HStack w='8'>
            <Tag colorScheme='zinc'>{lang.id}</Tag>
          </HStack>
          <Editable defaultValue={lang.title} onSubmit={handleTitleChange}>
            <EditablePreview cursor='pointer' />
            <EditableInput />
          </Editable>
        </HStack>
        <Switch isChecked={!lang.isDisabled} onChange={handleSwitchChange} />
      </Flex>
    </Box>
  )
}

export default LangRow
