import { FC, useState } from 'react'
import {
  Stack,
  Box,
  Image,
  VStack,
  HStack,
  Text,
  Button
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import { dbDeleteVegasUserPhoto } from 'controllers/vegas'
import { DBT } from 'types/internal'

type Props = {
  userId: string
  face: DBT.FaceT
}

const UserPhoto: FC<Props> = ({ face, userId }) => {
  const [loading, setLoading] = useState(false)

  const onDeleteClick = async () => {
    try {
      setLoading(true)
      await dbDeleteVegasUserPhoto(userId, face.id)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      w='full'
      spacing={{ base: 4, lg: 6 }}
    >
      <Box
        boxSize={40}
        borderWidth={1}
        borderColor={'zinc.200'}
        bg='zinc.100'
        rounded={'md'}
      >
        <Image w='full' h='full' objectFit={'contain'} src={face.url} />
      </Box>
      <VStack align={'start'}>
        <HStack>
          <Text color='zinc.600' fontSize='sm'>
            Created At:
          </Text>
          <Text color='zinc.600' fontSize='sm' fontWeight={'semibold'}>
            {dayjs(face.createdAt).format('lll')}
          </Text>
        </HStack>
        <HStack>
          <Text color='zinc.600' fontSize='sm'>
            Face ID:
          </Text>
          <Text color='zinc.600' fontSize='sm' fontWeight={'semibold'}>
            {face.id}
          </Text>
        </HStack>
        <Button
          size='xs'
          variant={'link'}
          colorScheme='gray'
          _hover={{ color: 'red.600' }}
          leftIcon={<DeleteIcon w={2.5} h={2.5} />}
          onClick={onDeleteClick}
          isLoading={loading}
        >
          Delete
        </Button>
      </VStack>
    </Stack>
  )
}

export default UserPhoto
