import {
  Textarea,
  Button,
  VStack,
  useToast,
  Box,
  Flex,
  Input,
  Text
} from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import { useState, FC, useEffect, useRef } from 'react'
import Label from 'components/Label'
import VoiceSelect from 'pages/settings/VoiceSelect'
import { Unsubscribe } from 'firebase/firestore'
import { DBT } from 'types/internal'
import _ from 'lodash'
import TemperatureInput from 'components/TemperatureInput'
import JsonSchemeInput from 'components/JsonSchemeInput'
import { dbSubscribeToConf, dbUpdateConf } from 'controllers/vegas'
import CannedResponse from 'pages/settings/CannedResponse'
import InterruptionMode from 'pages/settings/InterruptionMode'
import NavBar from 'components/NavBar'
import EditableTranslates from 'components/EditableTranslates'
import TranscriberSelect from 'components/TranscriberSelect'

type Props = {}

const VegasDemo: FC<Props> = () => {
  const [conf, setConf] = useState<DBT.AIPromptConfT | null>(null)
  const [savedConf, setSavedConf] = useState<DBT.AIPromptConfT | null>(null)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const toast = useToast()

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    unsubscribeRef.current = dbSubscribeToConf(conf => {
      setConf(conf)
      setSavedConf(conf)
    })
  }, [])

  const onSave = () => {
    if (conf) {
      try {
        const newConf = { ...conf }
        if (newConf.jsonScheme) {
          JSON.parse(newConf.jsonScheme)
        }
        dbUpdateConf(conf)
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Failed to save configuration, check JSON scheme',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const onVoiceChange = (voiceId: string) => {
    console.log('onVoiceChange', voiceId)
    if (conf) {
      setConf({ ...conf, voiceId })
    }
  }

  const onChangePrompt = (prompt: string) => {
    if (conf) {
      setConf({ ...conf, prompt })
    }
  }

  const onChangeInitialPhrase = (initialPhrase: string) => {
    if (conf) {
      setConf({ ...conf, initialPhrase })
    }
  }

  const onChangeFinalPhrase = (finalPhrase: string) => {
    if (conf) {
      setConf({ ...conf, finalPhrase })
    }
  }

  const onJsonSchemeChange = (jsonScheme: string) => {
    if (conf) {
      setConf({ ...conf, jsonScheme })
    }
  }

  const onChangeCannedResponse = (cr: Record<string, string>) => {
    if (conf) {
      setConf({ ...conf, cannedResponse: cr })
    }
  }

  const onChangeInterruptionMode = (mode: DBT.INTERRUPT) => {
    if (conf) {
      setConf({ ...conf, interruptionMode: mode })
    }
  }

  const onChageTranscriber = (v: DBT.TRANSCRIBER) => {
    if (conf) {
      setConf({ ...conf, transcriber: v })
    }
  }

  const onChange = (params: Partial<DBT.AIPromptConfT>) => {
    if (conf) {
      setConf({ ...conf, ...params })
    }
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => setConf({ ...conf, temperature: value })}
      />
    )
  }

  const actions = (
    <Button
      variant={'outline'}
      colorScheme='white'
      onClick={onSave}
      isDisabled={_.isEqual(conf, savedConf)}
      size='sm'
    >
      Save
    </Button>
  )

  const renderInitialPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <Label>Initial phrase</Label>
        <Textarea
          placeholder='Enter initial phrase here'
          value={_.get(conf, 'initialPhrase', '')}
          onChange={e => onChangeInitialPhrase(e.target.value)}
          fontSize={'sm'}
          minH='unset'
          overflow='hidden'
          w='100%'
          resize='none'
          minRows={2}
          as={ResizeTextarea}
        />
        <EditableTranslates
          phrase={_.get(conf, 'initialPhrase', '')}
          value={conf?.initialPhraseTranslates || {}}
          onChange={t => onChange({ initialPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  const renderFinalPhrase = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={2}>
        <Label>Final phrase</Label>
        <Textarea
          placeholder='Enter final phrase here'
          value={_.get(conf, 'finalPhrase', '')}
          onChange={e => onChangeFinalPhrase(e.target.value)}
          fontSize={'sm'}
          minH='unset'
          overflow='hidden'
          w='100%'
          resize='none'
          minRows={2}
          as={ResizeTextarea}
        />
        <EditableTranslates
          phrase={_.get(conf, 'finalPhrase', '')}
          value={conf?.finalPhraseTranslates || {}}
          onChange={t => onChange({ finalPhraseTranslates: t })}
        />
      </VStack>
    )
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Vegas Demo'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={{ base: 2, lg: 6 }}>
        <VStack w='full' spacing={10}>
          <VoiceSelect value={conf?.voiceId} onSelect={onVoiceChange} />

          <VStack w='full' align={'flex-start'} spacing={2}>
            <Label>Prompt</Label>
            <Textarea
              placeholder='Enter prompt here'
              value={_.get(conf, 'prompt', '')}
              onChange={e => onChangePrompt(e.target.value)}
              fontSize={'sm'}
              minH='unset'
              overflow='hidden'
              w='100%'
              resize='none'
              minRows={5}
              as={ResizeTextarea}
            />
          </VStack>
          <JsonSchemeInput
            value={conf?.jsonScheme || ''}
            onChange={onJsonSchemeChange}
          />
          <CannedResponse
            translations={conf?.cannedResponseTranslates || {}}
            onChangeTranslations={v =>
              onChange({ cannedResponseTranslates: v })
            }
            value={conf?.cannedResponse || {}}
            onChange={onChangeCannedResponse}
          />
          {renderInitialPhrase()}
          {renderFinalPhrase()}
          {renderTemperatureInput()}
          <InterruptionMode
            value={conf?.interruptionMode || DBT.INTERRUPT.DISABLED}
            onChange={onChangeInterruptionMode}
          />
          <TranscriberSelect
            value={conf?.transcriber || DBT.TRANSCRIBER.DEEPGRAM}
            onChange={onChageTranscriber}
          />
        </VStack>
      </VStack>
    </VStack>
  )
}

export default VegasDemo
