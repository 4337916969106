import { createReducer } from '@reduxjs/toolkit'
import { receiveLangs, logout } from 'model/actions'
import { DBT } from 'types/internal'

const initialState: Record<string, DBT.LangT> = {}

const authDataReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveLangs, (state, action) => action.payload)
    .addCase(logout, () => initialState)
})

export default authDataReducer
