import { httpsCallable } from 'firebase/functions'
import { db, functions } from 'controllers/db'
import _ from 'lodash'
import { DBT } from 'types/internal'
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore'

export const getLogs = async (interactionId: string) => {
  try {
    console.log('fetchLogs start')
    const callChatgpt = httpsCallable(functions, 'getTwoFactorLogs')
    const res = await callChatgpt({
      interactionId
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbSubscribeToConf = (
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'twoFactorConf')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToConf error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToConf error', e)
    return null
  }
}

export const dbUpdateConf = async (conf: Partial<DBT.AIPromptConfT>) => {
  const ref = doc(collection(db, 'settings'), 'twoFactorConf')
  await setDoc(ref, conf)
}

export const dbSubscribeToInterruptConf = (
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'interruptConf')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToInterruptConf error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToInterruptConf error', e)
    return null
  }
}

export const dbUpdateInterruptConf = async (
  conf: Partial<DBT.AIPromptConfT>
) => {
  const ref = doc(collection(db, 'settings'), 'interruptConf')
  await setDoc(ref, conf)
}
