import React from 'react'
import { Radio, RadioGroup, Stack, VStack, Text } from '@chakra-ui/react'
import { DBT } from 'types/internal'
import Label from 'components/Label'

interface InterruptionModeProps {
  value: DBT.INTERRUPT
  onChange: (value: DBT.INTERRUPT) => void
}

const InterruptionMode: React.FC<InterruptionModeProps> = ({
  value,
  onChange
}) => {
  return (
    <VStack align='start' spacing={4} w='full'>
      <Label>Interruption Mode</Label>
      <RadioGroup onChange={onChange} value={value} size='sm'>
        <Stack direction='row'>
          <Radio value={DBT.INTERRUPT.SMART}>Smart</Radio>
          <Radio value={DBT.INTERRUPT.REGULAR}>Regular</Radio>
          <Radio value={DBT.INTERRUPT.DISABLED}>Disabled</Radio>
        </Stack>
      </RadioGroup>
    </VStack>
  )
}

export default InterruptionMode
