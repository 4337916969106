import { HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  title: string
  actions?: ReactNode
}

const NavBar: FC<Props> = ({ title, actions }) => {
  const bgColor = useColorModeValue('zinc.900', 'white')
  const textColor = useColorModeValue('white', 'zinc.800')
  const borderColor = useColorModeValue('zinc.700', 'zinc.200')

  return (
    <HStack
      h='14'
      p={4}
      w='full'
      justify={'space-between'}
      borderBottomWidth={1}
      borderBottomColor={borderColor}
      bg={bgColor}
      color={textColor}
    >
      <Text size='lg' fontWeight={'bold'} pl={{ base: 8, lg: 0 }}>
        {title}
      </Text>
      <HStack>{actions}</HStack>
    </HStack>
  )
}

export default NavBar
