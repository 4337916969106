import { collection, onSnapshot, doc, setDoc } from 'firebase/firestore'
import { db } from 'controllers/db'
import { DBT } from 'types/internal'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveLangs } from 'model/actions'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'controllers/db'
import _ from 'lodash'

export const fetchLangs = async () => {
  try {
    const ref = doc(collection(db, 'settings'), 'langs')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const langs = sn.data() as Record<string, DBT.LangT> | undefined
        if (langs) {
          store.dispatch(receiveLangs(langs))
        }
      },
      err => {
        console.log(`fetchLangs error: ${err.message}`)
      }
    )
    addListener('langs', unsubscribe)
  } catch (e) {
    console.log('fetchLangs error', e)
  }
}

export const dbSubscribeToLangs = (
  callback: (langs: Record<string, DBT.LangT>) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'langs')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const langs = sn.data() as Record<string, DBT.LangT> | undefined
        if (langs) {
          callback(langs)
        }
      },
      err => {
        console.log(`dbSubscribeToLangs error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToLangs error', e)
    return null
  }
}

export const dbUpdateLangs = async (langs: Record<string, DBT.LangT>) => {
  const ref = doc(collection(db, 'settings'), 'langs')
  await setDoc(ref, langs)
}

export const translate = async (text: string, langs: string[]) => {
  try {
    console.log('translate start', { text, langs })
    const callTranslate = httpsCallable(functions, 'translate')
    const res = await callTranslate({
      text,
      langs
    })
    console.log('translation result:', res)
    return res.data
  } catch (e) {
    console.warn('translation error', e)
    return { error: _.get(e, 'message') }
  }
}
