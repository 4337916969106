import { VStack } from '@chakra-ui/react'
import { JsonEditor } from 'json-edit-react'
import { FC } from 'react'

type Props = {
  data: Record<string, any>
  onChange: (upd: Record<string, any>) => void
}

const JsonInput: FC<Props> = ({ data, onChange }) => {
  return (
    <VStack w='full'>
      <JsonEditor
        theme={[
          'githubLight',
          {
            container: {
              width: '100%',
              maxWidth: '100%',
              backgroundColor: 'zinc.100'
            }
          }
        ]}
        maxWidth={'full'}
        data={data}
        setData={data => onChange(data as Record<string, any>)}
      />
    </VStack>
  )
}

export default JsonInput
