import { liteClient } from 'algoliasearch/lite'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { useMemo, useState, useRef, useEffect, FC } from 'react'
import get from 'lodash/get'
import Label from 'components/Label'
import config from 'src/config'
import {
  FormControl,
  HStack,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import _ from 'lodash'

const searchClient = liteClient(config.algoliaAppId, config.algoliaSearchKey)

const algoliaIndex = 'vegasUsers'

type Props = {
  onSelect: (id: string) => void
}

const UsersSearch: FC<Props> = ({ onSelect }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const [suggestions, setAutocompleteState] = useState([])
  const [value, setValue] = useState('')

  useEffect(() => {
    suggestions.length > 0 ? onOpen() : onClose()
  }, [suggestions])

  console.log('suggestions', suggestions)

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange ({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          // @ts-ignore
          setAutocompleteState(get(state, ['collections', 0, 'items'], []))
        },
        // @ts-ignore
        getSources () {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: algoliaIndex,
              getItemInputValue ({ item }) {
                return item.query
              },
              getItems ({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: algoliaIndex,
                      //@ts-ignore
                      query,
                      params: {
                        hitsPerPage: 8
                        // highlightPreTag: '<mark>',
                        // highlightPostTag: '</mark>'
                      }
                    }
                  ]
                })
              },
              getItemUrl ({ item }) {
                return item.url
              }
            }
          ]
        }
      }),
    []
  )

  const onSuggestionClick = (id: string) => {
    if (inputRef.current) {
      inputRef.current.value = ''
      setAutocompleteState([])
      onSelect(id)
      autocomplete.setQuery('')
      onClose()
    }
  }

  const renderSuggestion = ({
    objectID,
    name,
    jobTitle,
    company,
    city,
    country,
    photoUrl
  }: {
    objectID: string
    name: string
    jobTitle?: string
    company?: string
    city?: string
    country?: string
    photoUrl?: string
  }) => {
    return (
      <HStack
        align={'flex-start'}
        w='full'
        key={objectID}
        _hover={{ bg: 'zinc.100' }}
        cursor={'pointer'}
        onClick={() => onSuggestionClick(objectID)}
      >
        <Image boxSize='60px' objectFit='cover' src={photoUrl} alt={name} />

        <VStack flex={1} align='flex-start' w='full' spacing={0} flexShrink={0}>
          <Text color={'zinc.600'} fontSize={'sm'} fontWeight={'semibold'}>
            {name}
          </Text>
          <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {objectID}
          </Text>
          <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {jobTitle} {company}
          </Text>
          <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {city} {country}
          </Text>
        </VStack>
      </HStack>
    )
  }

  return (
    <FormControl w='full'>
      <Label>Users search</Label>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom-start'
        closeOnBlur={false}
        initialFocusRef={inputRef}
        matchWidth
      >
        <PopoverTrigger>
          <Input
            ref={inputRef}
            variant='flushed'
            placeholder="Start typing user's name or ID to search"
            type={'search'}
            // value={
            //   autocomplete.getInputProps({ inputElement: inputRef.current })
            //     .value
            // }
            value={value}
            // @ts-ignore
            onChange={e => {
              setValue(e.target.value)
              if (_.size(e.target.value) >= 3) {
                autocomplete
                  .getInputProps({ inputElement: inputRef.current })
                  //@ts-ignore
                  .onChange(e)
              }
            }}
            //
          />
        </PopoverTrigger>
        <PopoverContent w={'full'}>
          <PopoverBody w={'full'}>
            <VStack
              w={'full'}
              divider={<StackDivider color='gray.50' />}
              overflowY={'auto'}
              spacing={1}
              maxH='lg'
            >
              {suggestions.map(renderSuggestion)}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  )
}

export default UsersSearch
