import { FC, useState } from 'react'
import { VStack, HStack, Input, Button, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import Label from 'components/Label'
import _ from 'lodash'
import EditableTranslates from 'components/EditableTranslates'

type Props = {
  value: Record<string, string>
  onChange: (value: Record<string, string>) => void
  translations: Record<string, Record<string, string>>
  onChangeTranslations: (v: Record<string, Record<string, string>>) => void
}

const CannedResponse: FC<Props> = ({
  value,
  onChange,
  translations,
  onChangeTranslations
}) => {
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')

  const handleEdit = (key: string, newValue: string) => {
    onChange({ ...value, [key]: newValue })
  }

  const handleAdd = () => {
    if (newKey && newValue) {
      onChange({ ...value, [newKey]: newValue })
      setNewKey('')
      setNewValue('')
    }
  }

  const handleDelete = (key: string) => {
    const newValue = { ...value }
    delete newValue[key]
    onChange(newValue)
  }

  return (
    <VStack spacing={2} align='stretch' w='full'>
      <Label>Canned responses</Label>
      {_.map(value, (val, key) => {
        const trs = _.get(translations, key)
        return (
          <VStack key={key} align='stretch' w='full'>
            <HStack align={'start'}>
              <Input value={key} isReadOnly size='sm' flex={1} />
              <VStack flex={2}>
                <Input
                  value={val}
                  size='sm'
                  onChange={e => handleEdit(key, e.target.value)}
                />
                <EditableTranslates
                  phrase={val}
                  value={trs}
                  onChange={v =>
                    onChangeTranslations({ ...translations, [key]: v })
                  }
                />
              </VStack>
              <IconButton
                w={'53px'}
                aria-label='Delete'
                variant={'ghost'}
                icon={<DeleteIcon />}
                onClick={() => handleDelete(key)}
                size={'sm'}
              />
            </HStack>
          </VStack>
        )
      })}
      <HStack>
        <Input
          placeholder='New key'
          value={newKey}
          onChange={e => setNewKey(e.target.value)}
          size='sm'
          flex={1}
        />
        <Input
          placeholder='New value'
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          size='sm'
          flex={2}
        />
        <Button size='sm' onClick={handleAdd} variant={'primary'}>
          Add
        </Button>
      </HStack>
    </VStack>
  )
}

export default CannedResponse
