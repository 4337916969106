import { FC, useEffect } from 'react'
import { HStack, Button, ButtonProps } from '@chakra-ui/react'
import { useDropzone, DropzoneOptions, FileWithPath } from 'react-dropzone'
import { AttachmentIcon } from '@chakra-ui/icons'

type Props = {
  options?: DropzoneOptions
  onFilesPicked: (files: FileWithPath[]) => void
  isLoading?: boolean
  buttonProps?: ButtonProps
  title?: string
}

const FilesPickerButton: FC<Props> = ({
  options,
  onFilesPicked,
  isLoading,
  buttonProps,
  title = 'Upload a photo'
}) => {
  const {
    getRootProps,
    getInputProps,

    acceptedFiles
  } = useDropzone(options)

  const processFiles = async () => {
    onFilesPicked([...acceptedFiles])
  }

  useEffect(() => {
    processFiles()
  }, [acceptedFiles])

  return (
    <HStack {...getRootProps()}>
      <Button
        variant={'outline'}
        leftIcon={<AttachmentIcon w={4} h={4} />}
        size='sm'
        isLoading={isLoading}
        {...buttonProps}
      >
        {title}
      </Button>
      <input {...getInputProps()} id='file-input' />
    </HStack>
  )
}

export default FilesPickerButton
