import { storage } from 'controllers/db'
import {
  ref,
  getBlob,
  getDownloadURL,
  getMetadata,
  uploadBytesResumable,
  StorageReference
} from 'firebase/storage'
// import Resizer from 'react-image-file-resizer'
import { StorageFileT } from 'types/internal'

// the method receives local url
export const saveFile = async (
  storagePath: string,
  fileUrl: string,
  onProgress?: (v: number) => void
) => {
  try {
    console.log('saveFile', storagePath, fileUrl)
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.log('saveFile error', e)
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileUrl, true)
      xhr.send(null)
    })
    // TODO: test it
    const storageRef = ref(storage, storagePath)
    console.log('storage ref', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, blob)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot =>
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      )
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    const url = await getDownloadURL(storageRef)
    console.log('download url is', url)
    const sf: StorageFileT = {
      url,
      size: snapshot.metadata.size,
      contentType: snapshot.metadata.contentType
    }
    console.log('sf', sf)
    return sf
  } catch (e) {
    console.log(e)
  }
}

// export async function saveImageBase64DataUrl (storagePath: string, base64Data, onProgress = () => null) {
//   const url = await saveFile(storagePath, base64Data, onProgress)
//   return url
// }

export const getContentType = async (url: string) => {
  const storagePath = url.split('o/')[1].split('?alt')[0].split('%2F').join('/')
  const storageRef = ref(storage, storagePath)
  const { contentType } = await getMetadata(storageRef)
  return contentType
}

export const saveFileObject = async (
  fileObject: File | Blob | Uint8Array | ArrayBuffer,
  storagePath: string,
  onProgress?: (v: number) => void,
  isPrivate?: boolean
) => {
  try {
    console.log('saveFileObject', fileObject, storagePath)
    const storageRef = ref(storage, storagePath)
    console.log('storageRef', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, fileObject)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot =>
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      )
    }
    uploadTask.catch((error: any) => {
      console.warn(error)
    })
    const snapshot = await uploadTask
    console.log('snapshot', snapshot)
    console.log('totalBytes', snapshot.metadata.size)

    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    if (!isPrivate) {
      const url = await getDownloadURL(storageRef)
      console.log('download url is', url)
      const sf: StorageFileT = {
        url,
        size: snapshot.metadata.size,
        contentType: snapshot.metadata.contentType
      }
      console.log('sf', sf)
      return sf
    } else {
      console.log('file is private')
      return undefined
    }
  } catch (e) {
    console.log('saveFileObject error: ', e)
  }
}

export const deleteFile = async (storagePath: String) => {
  console.log('deleteFile: the file will be not realy deleted', storagePath)
}

// export const createImageThumbnail = async (file: any, thumbWidth = 120) => {
//   return new Promise((resolve, reject) => {
//     try {
//       Resizer.imageFileResizer(
//         file,
//         thumbWidth,
//         thumbWidth,
//         'PNG',
//         100,
//         0,
//         (blob: any) => {
//           const resName = `thumb_${file.name}`
//           const resFile = new File([blob], resName)
//           resolve(resFile)
//         },
//         'blob'
//       )
//     } catch (e) {
//       reject(new Error('Image optimize error'))
//     }
//   })
// }

const downloadFilePromise = (r: StorageReference) => {
  return getBlob(r)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // createImageThumbnail,
  deleteFile,
  saveFileObject,
  getContentType,
  saveFile,
  downloadFilePromise
}
