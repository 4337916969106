import React, { useState } from 'react'
import { Box, Input, Button, Flex } from '@chakra-ui/react'
import { DBT } from 'types/internal'

interface AddLangRowProps {
  onAdd: (newLang: DBT.LangT) => void
}

const AddLangRow: React.FC<AddLangRowProps> = ({ onAdd }) => {
  const [newLangTitle, setNewLangTitle] = useState('')
  const [newLangId, setNewLangId] = useState('')

  const handleAddLang = () => {
    if (newLangTitle.trim() && newLangId.trim().length === 2) {
      const newLang: DBT.LangT = {
        id: newLangId.trim().toLowerCase(),
        title: newLangTitle.trim(),
        createdAt: Date.now(),
        isDisabled: false
      }

      onAdd(newLang)
      setNewLangTitle('')
      setNewLangId('')
    }
  }

  return (
    <Flex w='full'>
      <Input
        value={newLangId}
        onChange={e => setNewLangId(e.target.value)}
        placeholder='Enter 2-symbol code'
        mr={2}
        maxLength={2}
      />
      <Input
        value={newLangTitle}
        onChange={e => setNewLangTitle(e.target.value)}
        placeholder='Enter language name'
        mr={2}
      />
      <Button variant='primary' onClick={handleAddLang} size='md'>
        Add
      </Button>
    </Flex>
  )
}

export default AddLangRow
