import FilesPickerButton from 'components/FilesPickerButton'
import { FileWithPath } from 'react-dropzone'
import _ from 'lodash'
import { FC, useState } from 'react'
import { dbSearchVegasUserByPhoto } from 'controllers/vegas'
import { SearchIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react'

interface Props {
  onUserFound: (userId: string) => void
}

const SearchByPhoto: FC<Props> = ({ onUserFound }) => {
  const [processingFiles, setProcessingFiles] = useState(false)
  const toast = useToast()

  const convertToBase64Async = (file: FileWithPath): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const base64url = reader.result as string
        const base64 = base64url.replace(/^data:image\/?[A-z]*;base64,/, '')
        resolve(base64)
      }
      reader.onerror = error => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const onFilesPicked = async (files: FileWithPath[]) => {
    console.log('on files picked', files)
    const file = _.first(files)
    if (file) {
      setProcessingFiles(true)
      const base64 = await convertToBase64Async(file)
      const searchRes = await dbSearchVegasUserByPhoto(base64)
      console.log('searchRes', { searchRes })
      const faceId = _.get(searchRes, 'faceId')
      const userId = _.get(searchRes, 'detectedUserId')
      if (_.has(searchRes, 'error')) {
        toast({
          title: 'Error',
          description: _.get(searchRes, 'message'),
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } else if (_.isEmpty(faceId)) {
        toast({
          title: 'Warning',
          description: 'No user detected on the photo',
          status: 'warning',
          duration: 3000,
          isClosable: true
        })
      } else if (faceId && !userId) {
        toast({
          title: 'Info',
          description:
            'The user on the photo was not recognized or does not exist in amazon',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
      } else if (userId) {
        toast({
          title: 'Success',
          description: `User ${userId} is recognized`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        onUserFound(userId)
      }
      setProcessingFiles(false)
    }
  }

  return (
    <FilesPickerButton
      options={{
        accept: { 'image/jpeg': [], 'image/png': [] },
        multiple: false
      }}
      buttonProps={{
        variant: 'link',
        leftIcon: <SearchIcon w={4} h={4} />
      }}
      title='Search by photo'
      onFilesPicked={onFilesPicked}
      isLoading={processingFiles}
    />
  )
}

export default SearchByPhoto
