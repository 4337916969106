import { FC } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'

import AppRouter from 'navigation/AppRouter'
import store from 'model/store'
import theme from 'src/theme'

const App: FC = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
