import React from 'react'
import {
  VStack,
  Flex,
  Text,
  Input,
  Box,
  Button,
  Checkbox
} from '@chakra-ui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { translate } from 'controllers/langs'
import { motion, AnimatePresence } from 'framer-motion'

interface EditableTranslatesProps {
  phrase: string
  value: Record<string, string>
  onChange: (value: Record<string, string>) => void
}

const EditableTranslates: React.FC<EditableTranslatesProps> = ({
  phrase,
  value,
  onChange
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedLangs, setSelectedLangs] = React.useState<string[]>([])
  const langs = useSelector(state => state.langs)

  const toggleCollapsible = () => setIsOpen(!isOpen)

  const sortedLangs = React.useMemo(() => {
    return _.sortBy(langs, 'createdAt')
  }, [langs])

  const generateTranslations = async () => {
    setIsLoading(true)
    try {
      const res = await translate(phrase, selectedLangs)
      if (res) {
        onChange({ ...value, ...(res as Record<string, string>) })
      }
    } catch (error) {
      console.error('Translation error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheckboxChange = (langId: string) => {
    setSelectedLangs(prev =>
      prev.includes(langId)
        ? prev.filter(id => id !== langId)
        : [...prev, langId]
    )
  }

  const handleSelectAll = () => {
    if (selectedLangs.length === sortedLangs.length) {
      setSelectedLangs([])
    } else {
      setSelectedLangs(sortedLangs.map(l => l.id))
    }
  }

  return (
    <Box w='full'>
      <Button onClick={toggleCollapsible} size='sm' mb={2} variant={'link'}>
        {isOpen ? 'Hide Translations ' : 'Show Translations'} ({_.size(value)})
        {isOpen ? <ChevronUpIcon ml={1} /> : <ChevronDownIcon ml={1} />}
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
          >
            <VStack
              spacing={2}
              w='full'
              align='start'
              borderWidth={1}
              borderColor={'zinc.200'}
              bg='zinc.100'
              p={4}
              rounded='8px'
            >
              <Button size='xs' onClick={handleSelectAll} variant={'link'}>
                {selectedLangs.length === sortedLangs.length
                  ? 'Deselect All'
                  : 'Select All'}
              </Button>
              {sortedLangs.map(l => {
                const v = _.get(value, l.id)
                return (
                  <Flex key={l.id} w='full' alignItems='center'>
                    <Checkbox
                      mr={2}
                      isChecked={selectedLangs.includes(l.id)}
                      onChange={() => handleCheckboxChange(l.id)}
                    />
                    <Text
                      fontWeight='semibold'
                      minWidth='40px'
                      mr={2}
                      fontSize={'xs'}
                    >
                      {l.id}:
                    </Text>
                    <Input
                      value={v || ''}
                      variant={'flushed'}
                      size='xs'
                      onChange={e => {
                        onChange({
                          ...value,
                          [l.id]: e.target.value
                        })
                      }}
                      placeholder={`Enter translation for ${l.title}`}
                      flex={1}
                    />
                  </Flex>
                )
              })}
              <Button
                mt={2}
                size='sm'
                variant={'outline'}
                onClick={generateTranslations}
                isLoading={isLoading}
                isDisabled={_.isEmpty(selectedLangs)}
              >
                Generate Translations
              </Button>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default EditableTranslates
