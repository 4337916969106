import React, { useState, useMemo, useEffect, useRef } from 'react'
import {
  Button,
  VStack,
  Text,
  Input,
  Flex,
  IconButton,
  HStack
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import _ from 'lodash'
import NavBar from 'components/NavBar'
import EditableTranslates from 'components/EditableTranslates'
import Label from 'components/Label'
import {
  dbSubscribeToLocalization,
  dbUpdateLocalization
} from 'controllers/twoFactorLocalization'
import { Unsubscribe } from 'firebase/firestore'

const TwoFactorLocalization: React.FC = () => {
  const [newKey, setNewKey] = useState('')
  const [newEnValue, setNewEnValue] = useState('')
  const [dict, setDict] = useState<Record<string, Record<string, string>>>({})
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const [savedDict, setSavedDict] = useState<
    Record<string, Record<string, string>>
  >({})

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    unsubscribeRef.current = dbSubscribeToLocalization(dict => {
      setDict(dict)
      setSavedDict(dict)
    })
  }, [])

  const orderedKeys = useMemo(() => {
    return _.keys(dict).sort()
  }, [dict])

  const onSave = () => {
    dbUpdateLocalization(dict)
  }

  const onExport = () => {
    const jsonString = JSON.stringify(dict, null, 2)
    const blob = new Blob([jsonString], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'localization.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const actions = (
    <HStack spacing={2}>
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onExport}
        size='sm'
      >
        Export
      </Button>
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(dict, savedDict)}
        size='sm'
      >
        Save
      </Button>
    </HStack>
  )

  const deleteKey = (key: string) => {
    setDict(prevDict => {
      const newDict = { ...prevDict }
      delete newDict[key]
      return newDict
    })
  }

  const renderDict = () => {
    return orderedKeys.map(key => (
      <VStack key={key} w='full' align='start' spacing={0}>
        <Flex w='full' justify='space-between' align='start'>
          <Text fontWeight='bold' flex={1}>
            {key}
          </Text>
          <VStack flex={2} align={'start'} spacing={0}>
            <Text>{_.get(dict, [key, 'en'], '')}</Text>

            <EditableTranslates
              phrase={_.get(dict, [key, 'en'])}
              value={dict[key] || {}}
              onChange={(newValue: Record<string, string>) => {
                setDict(prevDict => ({
                  ...prevDict,
                  [key]: newValue
                }))
              }}
            />
          </VStack>
          <IconButton
            aria-label='Delete key'
            icon={<DeleteIcon />}
            size='sm'
            variant='ghost'
            colorScheme='red'
            onClick={() => deleteKey(key)}
          />
        </Flex>
      </VStack>
    ))
  }

  const renderAddKeyButton = () => {
    return (
      <VStack
        w='full'
        p={4}
        bg='gray.50'
        borderBottom='1px'
        borderColor='gray.200'
        spacing={4}
        align={'start'}
      >
        <Label>Add New Key</Label>
        <Flex w='full'>
          <Input
            bg='white'
            placeholder='Enter new key'
            value={newKey}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewKey(e.target.value)
            }
            mr={2}
          />
          <Input
            bg='white'
            placeholder='Enter English value'
            value={newEnValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewEnValue(e.target.value)
            }
            mr={2}
          />
          <Button
            variant={'primary'}
            onClick={() => {
              if (newKey && !dict[newKey]) {
                setDict(prevDict => ({
                  ...prevDict,
                  [newKey]: { en: newEnValue }
                }))
                setNewKey('')
                setNewEnValue('')
              }
            }}
            isDisabled={!newKey || !!dict[newKey] || !newEnValue}
          >
            Add
          </Button>
        </Flex>
      </VStack>
    )
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Two Factor Localization'} actions={actions} />
      {renderAddKeyButton()}
      <VStack w='full' overflow={'auto'} p={6}>
        <VStack w='full' spacing={2}>
          {renderDict()}
        </VStack>
      </VStack>
    </VStack>
  )
}

export default TwoFactorLocalization
